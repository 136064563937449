import React, {
    FormEvent,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from "react";
import { ValidationService } from "../../../services/ValidationService";
import { useDependency } from "../../../contexts/DependencyContext";
import FormElementsFactory, { FormElement } from "./FormElementsFactory";
import styles from "./Form.module.scss";

interface PrismicTextField {
    type: string, text: string, spans: any[]
}
interface FormProps {
    name: string;
    title: string;
    subtitle: Array<PrismicTextField>;
    data: any;
    elements: FormElement[];
    submitLabel?: string;
    recaptchaError?: boolean;
    formErrors: object[];
    handleInputChange: (e: FormEvent) => void;
    handleSelectChange: (name: string, value: string) => void;
    handleFormSubmission: (e: FormEvent) => void;
    handleBlurValidation: (e: FormEvent) => void;
    deleteTryClass: (e: FormEvent) => void;
}

const Form = (props: PropsWithChildren<FormProps>): ReactElement => {
    const [submitButtonEnabled, setSubmitButtonState] = useState<boolean>(
        false
    );
    const [formElements, setFormElements] = useState<FormElement[]>(
        props.elements
    );
    const validationService = useDependency(ValidationService);

    const handleFormValidation = (e: FormEvent) => {
        setFormElements(
            formElements.map((element: FormElement) => {
                if (!element) return undefined as any;

                if (element.name !== (e.target as HTMLInputElement).name)
                    return element;
                else
                    return {
                        ...element,
                        withErrors: !validationService.setValidation(e.target.value, e.target.type),
                    };
            })
        );
    };

    useEffect(() => {
        if (!props.data) return;

        const mandatoryFields = props.elements.filter(x => !x.validations?.optional);
        const formData = props.elements.map(element => {
            Object.entries(props.data).forEach(entry => {
                if (element.name === entry[0]) element.data = entry[1];
            });

            return element;
        });
        const completedFields = formData.filter(x => !x.validations?.optional && x.data);

        setSubmitButtonState(
            !props.recaptchaError &&
                mandatoryFields.length === completedFields.length
        );
    }, [props.data]);

    const { skipValidation } = props;

    return (
        <div className={styles.form}>
            <h2>{props.title}</h2>
            {props.subtitle &&
                props.subtitle.map((x: any, i: number) => (
                    <p key={i}>{x.text}</p>
                ))}
            <form
                name={props.name}
                onSubmit={props.handleFormSubmission}
            >
                {formElements.map((element, i) => (
                    <div key={i} className={styles.formControlGroup}>
                        <FormElementsFactory
                            withErrors={props.formErrors[element.name]}
                            {...{ element, handleFormValidation, skipValidation }}
                            elements = {formElements}
                            handleInputChange={props.handleInputChange}
                            handleSelectChange={props.handleSelectChange}
                            handleBlurValidation={props.handleBlurValidation}
                            formData={props.data}
                            value={props.data[element.name]}
                            handleDeleteTryClass={props.deleteTryClass}
                        />
                    </div>
                ))}

                <div className={styles.formControlGroup}>
                    <div
                        className="g-recaptcha"
                        data-sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                    />

                    {props.recaptchaError && (
                        <small className="errorMessage">
                            Please verify that you're human and try again
                        </small>
                    )}
                </div>

                <button
                    type="submit"
                    disabled={!submitButtonEnabled}
                    className={styles.submitBtn}
                >
                    <span>{props.submitLabel || "Submit"}</span>
                </button>
            </form>
        </div>
    );
};

export default Form;
