import React, { FormEvent, PropsWithChildren, ReactElement, useEffect, useState, useRef } from "react";
import useWindowWidth, { heroDesktopMinWidth } from "../../hooks/useWindowWidth";
import { graphql } from "gatsby"
import styles from "./CorporateWellness.module.scss"
import { encode, orderLocations, slugify, getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";
import { messageIcons } from "../../shared/icons";
import { FormElement, FormElementType } from "../../components/shared/Form/FormElementsFactory";
import Helmet from "../../components/Helmet";
import Hero from "../../components/shared/HeroStaticPages";
import Form from "../../components/shared/Form";
import { SelectOption } from "../../components/shared/CustomSelect";
import SubmitPopup from "../../components/shared/Form/SubmitPopup";
import { useRecaptchaScript } from "../../hooks/useRecaptchaScript";
import { useDependency } from "../../contexts/DependencyContext";
import { ValidationService } from "../../services/ValidationService";
import { isEmpty } from "lodash";
import DashboardApiService from "../../services/DashboardApiService";
import formatters from "../../templates/Account_create_page/components/Form/formatters";
import Spinner from "../../components/shared/Spinner";

interface ITermTexts {
    type: string, text: string, spans: any[]
}

interface Popup {
    icon: ReactElement;
    title: string;
    message: string;
}

const CorporateWellness = (props: PropsWithChildren<any>): ReactElement => {
    const data = JSON.parse(props.data.allPrismicCorporateWellnessPage.edges[0].node.dataString);
    let locations = props.data.allPrismicLocations.edges.map(edge => edge.node.data.location);

    locations = orderLocations(locations);

    const isDesktop = useWindowWidth(heroDesktopMinWidth);
    const background = isDesktop ? data.hero_background?.url : data.mobile_background?.url || data.hero_background?.url;
    
    const heroAltText = (): string => 
    {
        let altText = "";
        if (isDesktop && data.hero_alt_text?.length > 0) {
            altText = data.hero_alt_text[0].text;
        } else if (!isDesktop && data.hero_alt_text_mobile?.length > 0) {
            altText = data.hero_alt_text_mobile[0].text;
        }
        return altText;
    }

    //#region form
    const contactFormElements = [
        {
            type: FormElementType.Select,
            name: "gym",
            generateHiddenForm: true,
            placeholder: data.club_dropdown_label ? data.club_dropdown_label[0]?.text : "Please select a club",
            options: locations.map((x: any) => ({
                label: x.name,
                id: x.businessUnitCode,
                selected: false,
            })),
        },
        {
            type: FormElementType.Text,
            name: "Company",
            placeholder: data.company_input_label ? data.company_input_label[0]?.text : "Company",
            errorMessage: "Invalid company format",
        },
        {
            type: FormElementType.Number,
            name: "employees",
            placeholder: data.employees_label ? data.employees_label[0]?.text : "Number of employees",
            errorMessage: "Invalid number format",
        },
        {
            type: FormElementType.Text,
            name: "first-name",
            placeholder: "First Name",
            errorMessage: "Invalid name format",
            validations: {
                text: true
            }
        },
        {
            type: FormElementType.Text,
            name: "last-name",
            placeholder: "Last Name",
            errorMessage: "Invalid name format",
            validations: {
                text: true
            }
        },
        {
            type: FormElementType.Text,
            name: "job-title",
            placeholder: "Job Title",
            errorMessage: "Invalid job format",
            validations: {
                text: true
            }
        },
        {
            type: FormElementType.Email,
            name: "email",
            placeholder: data.email_input_label ? data.email_input_label[0]?.text : "Email",
            errorMessage: "Invalid email format",
            validations: {
                remote: {
                    val: "validate/email",
                    customError: "We couldn't verify your email address, please check your input"
                }
            },
        },
        {
            type: FormElementType.Tel,
            name: "phone",
            placeholder: data.phone_input_label ? data.phone_input_label[0]?.text : "Phone",
            errorMessage: "Invalid phone format",
            formatter: "phoneNum",
            validations: {
                tel: true,
                remote: {
                    val: "validate/phone",
                    customError: "We couldn't validate your phone number, please check your input"
                }
            },
        },
        {
            type: FormElementType.Checkbox,
            name: "decision maker",
            placeholder: data.decision_maker_label ? data.decision_maker_label[0]?.text : "",
            validations: {
                optional: true
            }
        },
        {
            type: FormElementType.Checkbox,
            name: "consent",
            placeholder: data.consent_text ? data.consent_text[0]?.text : "",
        }
    ];

    const [formData, setFormData] = useState<any>({});
    const [formElements, setFormElements] = useState<FormElement[]>(contactFormElements as any);
    const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
    const [popup, setPopup] = useState<Popup>(null as any);
    const [formErrors, setFormErrors] = useState({});
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const validationService = useDependency(ValidationService);
    const errorsRef = useRef({});

    const skipValidation = (name, value) => {
        const arrCopy = [...formElements];
        const optional = arrCopy.find(e => e.name === name);
        optional.validations.skip = value;        
        optional.validations.optional = value;
        const errors = {...formErrors};

        if (value) {
            delete errors[name];
        } else {
            errors[name] = errorsRef.current[name];
        }

        setFormErrors(errors)
        setFormElements(arrCopy);
    }   

    const handleInputChange = ({ target }: FormEvent): void => {
        const input = target as HTMLInputElement | HTMLTextAreaElement;

        const formElement = contactFormElements.find(fElem => fElem.name === target.name);

        let value = input.value;

        if (formElement?.formatter) {
            const formatterObj = formatters[formElement.formatter];
            const Formatter = formatterObj.class;
            let formatterInstance;

            if (formatterObj.instances[input.name]) {
                formatterInstance = formatterObj.instances[input.name];
            } else {
                formatterObj.instances[input.name] = new Formatter();
                formatterInstance = formatterObj.instances[input.name];
            }

            value = formatterInstance.format(input.value);
        }

        setFormData({
            ...formData,
            [input.name]:
                input.type === FormElementType.Checkbox
                    ? (input as HTMLInputElement).checked
                    : value,
        });
    };

    const handleBlurValidation = async ({ target }: FormEvent): void => {
        const errors = {};

        const { name, type, validations } = formElements.find(
            e => e.name === target.name
        );

        if (validations) {
            for (const key in validations) {
                const validationValue = validations[key];
                const value = formData[name];
                let isValid = false;

                if (validations["optional"] && !value) {
                    break;
                }

                isValid = await validationService.setValidation(
                    value,
                    key,
                    validationValue,
                    name
                );

                if (!isValid) {
                    if (!errors[name]) {
                        errors[name] = [];
                    }

                    let customMsg = "";

                    if (typeof validationValue === "object") {
                        if (validationValue.customError) {
                            customMsg = validationValue.customError;
                        }
                    }

                    const errMsg = customMsg || formElements.find(e => e.name === name)?.errorMessage;

                    errors[name][0] = errMsg;
                    errorsRef.current[name] = errMsg;                 
                }
            }
        }

        if (!isEmpty(errors)) {
            setFormErrors({
                ...formErrors,
                ...errors,
            });
        } else {
            const _formErrors = { ...formErrors };

            delete _formErrors[name];

            setFormErrors(_formErrors);
        }
    };

    const handleSelectChange = (name: string, value: string): void => {
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmission = async (e: FormEvent): void => {
        e.persist();
        e.preventDefault();
        setIsLoad(true);


        const form = e.target as HTMLFormElement;
        const recaptchaResponse = grecaptcha.getResponse();
        const selectOptions = formElements.filter(x => x.options)[0];
        const selectedForm = selectOptions.options?.filter(option => option.selected)[0];
        const errors = {};

        setRecaptchaError(!recaptchaResponse);

        if (!recaptchaResponse) {
            setIsLoad(false);
            return;
        }

        if (formData) {
            for (const formElem of formElements) {
                const { name, type, validations } = formElem;

                if (validations) {
                    if (validations.skip) break;

                    for (const key in validations) {
                        const validationValue = validations[key];
                        const value = formData[name];
                        let isValid = false;

                        isValid = await validationService.setValidation(
                            value,
                            key,
                            validationValue,
                            name
                        );

                        if (!isValid) {
                            if (!errors[name]) {
                                errors[name] = [];
                            }

                            let customMsg = "";

                            if (typeof validationValue === "object") {
                                if (validationValue.customError) {
                                    customMsg = validationValue.customError;
                                }
                            }

                            const errMsg = customMsg || formElements.find(e => e.name === name)?.errorMessage;

                            errors[name][0] = errMsg;
                            errorsRef.current[name] = errMsg; 
                        }
                    }
                }
            }

            if (isEmpty(errors)) {
                const postData = {
                    name: "corporate-wellness",
                    businessUnitCode: locations.find(l => l.name === formData.gym).businessUnitCode,
                    formData: formData,
                    recaptchaToken: recaptchaResponse,
                };

                const response = await DashboardApiService.submitForm(postData);

                if (response.data.success) {
                    setIsLoad(false);
                    props.navigate("/corporate-wellness/thank-you");
                } else {
                    setIsLoad(false);
                    setPopup({
                        icon: messageIcons.error,
                        title: "Something went wrong",
                        message: "Please try again",
                    });
                }
            } else {
                setIsLoad(false);
                setFormErrors(errors);
            }
        }
    };


    useEffect(() => {
        if (!popup) return;
        setTimeout(() => window.location.reload(), 3000);
    }, [popup]);

    useRecaptchaScript();

    //#endregion 

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Corporate Wellness")}
                description={getSeoDescription(data, "Corporate Wellness page")}
                keywords={getSeoKeywords(data, "")} 
            />
            
            {background && <Hero img={background} alt={heroAltText()} />}
            <main className={`mainBox ${styles.mainWrapper}`}>
                <section className={styles.mainInformation}>
                    {data.terms_title && <h2 className={styles.mainTitle}>{data.terms_title[0].text}</h2>}
                    {data.terms_introduction &&
                        data.terms_introduction.map((introduction: ITermTexts, i: number) => (
                            <p className={styles.mainDescription} key={i}>{introduction.text}</p>
                        ))}
                </section>
                <section>
                    {data.terms_section && data.terms_section.map((information: { term_title: Array<ITermTexts>, term_information: Array<ITermTexts> }, i: number) => {
                        return <div key={i}>
                            <h3 className={styles.termSectionTitle}>{information.term_title[0]?.text}</h3>

                            {information.term_information.map((term: ITermTexts, i: number) => (
                                <p className={styles.termSectionDescription} key={i}>{term?.text}</p>
                            ))}

                        </div>
                    })}
                </section>
            </main>
            {/* Contact Form */}

            <div className={`${styles.formWrapper} mainBox`}>
                <div
                    className={styles.topSection}
                >
                    {data.section_title && data.section_title[0] && (
                        <h2 className={styles.topSectionTitle}>{data.section_title[0].text}</h2>
                    )}

                    {data.section_text && data.section_text[0] && (
                        <p className={styles.topSectionParagraph}>{data.section_text[0].text}</p>
                    )}
                </div>


                <div className={styles.bottomSection}>
                    <Form
                        name="corporate-contact"
                        title="Contact Us"
                        data={formData}
                        formErrors={formErrors}
                        handleBlurValidation={handleBlurValidation}
                        elements={formElements}
                        recaptchaError={recaptchaError}
                        {...{
                            handleInputChange,
                            handleSelectChange,
                            handleFormSubmission,
                            skipValidation
                        }}
                    />
                </div>

                {popup && (
                    <SubmitPopup
                        icon={popup.icon}
                        title={popup.title}
                        message={popup.message}
                        handleClose={() => window.location.reload()}
                    />
                )}

            </div>

            {isLoad && <Spinner></Spinner>}

            {/* End Contact form */}
        </>
    );
};

export const query = graphql`
    query {
        allPrismicCorporateWellnessPage {
            edges {
                node {
                    dataString
                }
            }
        }
        allPrismicLocations {
            edges {
                node {
                    id
                    data {
                        location {
                            businessUnitCode
                            name
                            division
                        }
                    }
                }
            }
        }
    }
`;
export default CorporateWellness;